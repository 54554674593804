

.ume {
    text-align: center;
  }
  
.ume p {
    margin-top: 8vh;
}
.redirect-box {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 4vh;
    border: 2px solid black;
    background-color: white;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.redirect-box:hover {
    background-color: black;
    color: white;
}