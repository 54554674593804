/* 样式容器 */
.navigation {
  
  display: flex;
  justify-content: space-around; /* 平均分配空间 */
  align-items: center; /* 垂直居中 */
  margin: 2% 5%;
  padding: 0;
  list-style: none;
  font-family: 'Poppins'; /* 设置字体为 Poppins */
  
}

/* 导航项样式 */
.navigation a {
  cursor: pointer;
  text-decoration: none; /* 移除默认下划线 */
  font-size: calc(1em + 0.2vw);
  color: gray;
  padding: 5px 15px;
  transition: all 0.3s;
  border-bottom: 2px solid transparent; /* 默认无边框 */

  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* active 状态样式 */
.navigation a.active {
  font-weight: bold;
  color: black;
  border-bottom: 2px solid black; /* 显示下划线 */

}

/* hover 状态样式 */
.navigation a:hover {
  color: black;


}

/* 未选中状态 hover 的边框效果 */
.navigation a:not(.active):hover {
  border-bottom: 2px solid lightgray; /* 悬停时浅色下划线 */
}



@media (max-width: 768px) {
  
@media (max-width: 768px) {

  .navigation {
    font-size: calc(0.8em + 0.2vw);
    margin-top: 20%;
  }
}
}