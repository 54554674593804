
.center-h2 h2 {
    color: #000;
    text-align: center;

font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 200%; /* 60px */
}

.onigiri-container li{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 200%; /* 32px */
}

.onigiri-container img
{
    width: 100%;
    height: auto;
}