.page{
  margin: 0 5%;
}
.full-width {
  width: 100%;
}

.page-image {
  position: relative;
  width: 100%;
  height: 372px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-text {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
  font-size: 78px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.page-content {
  padding: 20px;
  margin: 0 10%;
  margin-bottom: 10vh;
}

.page-content p {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 643;
  line-height:  114.286% ;
}



.contact-us {
  color: black;
  font-size: 1rem; 
  text-align: center;
}

.page-content a {
  color: black;
} 
@media (max-width: 768px) {
  .page-image {
    height: 200px;
  }

  .page-text {
    font-size: 40px;
  }

  .page-content p {
    font-size: 16px;
  }

  .contact-us {
    font-size: 0.875rem;
  }
}