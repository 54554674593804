.footer {
    display: flex;
    justify-content: space-between; /* 均匀分布三列 */
    align-items: flex-start;
    padding: 40px 100px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    font-family: Arial, sans-serif;
    flex-wrap: wrap;
}

.footer-column {
    flex: 1; /* 每列占据相同空间 */
    display: flex;
    flex-direction: column;
    align-items: center; /* 在每列内水平居中对齐 */
    margin: 0px 20px; /* 保持各列之间的间距 */
    min-width: 200px; /* 保证在小屏幕上的最小宽度 */
    text-align: center;
}

.logo {
    width: 300px;
    height: auto;
    /* margin-bottom: 20px; */
}

.address {
    font-size: 14px;
    margin-bottom: 20px;
    color: #333;
}

.social-icons {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.footer-links {
    list-style-type: none;
    padding: 0;
    margin: 40px 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #000;
}
.social-icons a {
    text-decoration: none;
    color: #333;

}

@media (max-width: 768px) {
    .logo {
        width: 120px;
        height: auto;
        margin-bottom: 20px;
    }
    
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .footer-column {
        margin: 0 0 0 0; /* 小屏幕下各列之间的间距 */
    }

    .address {
        text-align: center;
    }

    .footer-links {
        text-align: center;
    }
}
