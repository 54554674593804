.image-container {
    width: 100%;
    text-align: center; /* 使图片容器居中 */
    margin-top: 50px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
  }
 