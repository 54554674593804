.styled-header {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }
  
  .first-word {
    color: #000;
    font-weight: 700;

    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    
  }
  @media (max-width: 768px) {
    .styled-header .first-word {
      color: #000;
  
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      
    }
  }