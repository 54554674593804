/* 父容器 */
.gift-card {
  display: flex;
  justify-content: space-between; /* 三部分分开布局 */
  align-items: center; /* 垂直居中 */
  /* max-width: 1000px; */
  width: 100%;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);  background-color: #fff; /* 背景颜色 */
  overflow: hidden; /* 防止内容溢出 */
}

/* 左侧部分 */
.gift-card-left {
  flex: 1; /* 占三分之一 */
  text-align: center; /* 居中对齐 */
}

.gift-card-left h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

/* 中间部分 */
.gift-card-center {
  flex: 1; /* 占三分之一 */
  text-align: left; /* 文字靠右对齐 */
}

.gift-card-center p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 10px;
  color: #333;
  text-align: left;
}

.gift-card-center .gift-card-link {
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none; /* 去掉下划线 */
  border-bottom: 2px solid black;
  transition: all 0.3s ease-in-out;
}

.gift-card-center .gift-card-link:hover {
  color: #555;
  border-bottom-color: #555; /* 修改边框颜色 */
}

/* 右侧部分 */
.gift-card-right {
  flex: 1; /* 占三分之一 */
  text-align: center; /* 居中对齐 */
}

.gift-card-right img {
  max-width: 50%;
  height: auto; /* 自动调整高度 */
  border-radius: 8px; /* 图片圆角 */
}
/* ...existing code... */

@media (max-width: 768px) {
  .gift-card {
    width: 90%;
    flex-direction: column; /* 竖着排列 */
    align-items: center; /* 居中对齐 */
  }


  
  .gift-card-left,
  .gift-card-center,
  .gift-card-right {
    width: 100%; /* 占满宽度 */
    text-align: center; /* 居中对齐 */
    margin-bottom: 10px; /* 底部间距 */
  }

  .gift-card-left:last-child,
  .gift-card-center:last-child,
  .gift-card-right:last-child {
    margin-bottom: 0; /* 最后一个元素没有底部间距 */
  }
  .gift-card-center p {

    text-align: center;
  }
  
}