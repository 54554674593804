
@media (min-width: 769px) {
.header {
  display: flex;            /* 使用 flex 布局 */
  justify-content: center;  /* 水平居中对齐 */
  align-items: center;      /* 垂直居中对齐 */
  height: 150px;            /* 设置一个合适的高度，根据需要调整 */
  padding: 20px;            /* 可选：调整 padding 使页面布局看起来更舒适 */
  background-color: #f9f9f9; /* 可选：为 header 添加背景色 */
}

.logo {
  width: 329px;
  height: 181px;
  flex-shrink: 0;
}
.full-width {
  width: 100%;    
  overflow: hidden; 
}
.full-width .banner {
  width: 100%;  
  height: 618px;  
  display: block;  
}
.process-section {
  display: flex;
  justify-content: center;
  padding: 20px;
}
/*  一行
.process {
  display: flex;
  gap: 20px;
  align-items: center; 
} */

/* 一列 */
.process {
  display: flex;
  flex-direction: column; /* 改为纵向排列 */
  gap: 20px;
  align-items: center; /* 让子元素在水平方向居中 */
}

.step {
  text-align: center;
}

.step img {
  width: 25vw;
  height: auto;
}

.onigiri-display, .banner-footer-display{
  display: flex;
  justify-content: center; 
  margin-bottom: 50px;
}

.onigiri-header,.banner-footer {
  width: 70vw;         /* 你可以根据需要调整这个宽度 */
  text-align: left;        /* 文字右对齐 */
  display: flex;
  flex-direction: column;   /* 将子元素垂直排列 */
  align-items: center;      /* 图片和标题居中对齐 */
}

.onigiri-header h1,
.onigiri-header p,
.onigiri-header img {
  margin-bottom: 20px; 
}

.onigiri-header img, .banner-footer img {
  width: 100%;         /* 限制最大宽度以适应页面 */
  height: auto;             /* 保持纵横比 */
}

.vertical-container {
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 20px;
}

.vertical-text {
  writing-mode: vertical-rl;
  font-size: 24px;
  text-align: center;
}

.onigiri-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  margin: 20px 200px;
}
.tempura-list
{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
  margin: 20px 100px;
}
.sashimi-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
  margin: 20px 100px;
}
.sashimi-list li {
  list-style-type: none;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.item {
  text-align: center;
}

.item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.item span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
}


}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .logo {
    width: 200px;
    height:80px;
  }

  .full-width .banner {
    width: 100%;  
    height:200px;  
    display: block;  
  }
  .process {
    flex-direction: column;
    gap: 10px;
  }

  .step img {
    width: 80vw;
  }

  .onigiri-header, .banner-footer {
    width: 90vw;
    text-align: center;
  }

  .onigiri-list, .tempura-list, .sashimi-list {
    grid-template-columns: 1fr;
    margin: 20px 20px;
  }

  .item img {
    width: 80px;
    height: 80px;
  }
  
}


@media (max-width: 1500px) {
  .img {
    display: block;
    margin: 0 auto;
  }
  .tempura-list {
    display: flex;
    flex-direction: column-reverse; /* 反转元素顺序 */
    align-items: center;
  }
}