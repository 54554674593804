.contact-us {
    text-align: center;
    width: 100%;
}
.contact-us h1{
    color: #000;

text-align: center;
font-family: Poppins;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 200%; /* 100px */
}
.contact-us p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
}

.contact-us img{
   margin-top: 50px;
}

/* .image-container img {
    width: 80%;
} */

@media (max-width: 768px) {
    .contact-us h1 {
        font-size: 30px;
        line-height: 150%; /* 45px */
    }

    .contact-us p {
        font-size: 14px;
        line-height: 150%;
    }

    .contact-us img {
        width: 100%;
    }
}