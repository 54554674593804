

.menu-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    text-align: center;
}


.menu-column {
    flex: 1;
    margin: 10px;
}

.menu-column h2 {
    color: #333;
}

.menu-column ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.menu-column li {
    margin-bottom: 5px;
    color: #666;
}

.menu-container img {
    max-width: 100%;
    height: auto;
}

.image-container {
    width: 100%;
    text-align: center; /* 使图片容器居中 */
  }

  .image-container img {
    width: 100%;
    height: auto;
  }
  
  .menu-layout {
    max-width: 100%;
  }

@media (max-width: 768px) {
    .menu-container {
        flex-direction: column;
        padding: 10px;
    }

    .menu-column {
        margin: 5px 0;
    }

    .menu-column h2 {
        font-size: 1.5em;
    }

    .menu-column li {
        font-size: 1em;
    }

    .image-container img {
        width: 100%;
    }
}