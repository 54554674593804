.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative; /* 为了让 center-container 绝对居中 */
}

.center-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* 绝对居中 */
}



.navbar {
    margin-left: auto; /* 使 NavBar 靠右对齐 */
}
