.reviews-container {
  width: 100%;
}

.reviews-row {
  display: flex;
  justify-content: space-between;
}

.reviews-center {
  margin-top: 20px;
  text-align: center;
}

/* 手机端样式 */
@media (max-width: 768px) {
  .reviews-row {
    display: none; /* 隐藏 reviews-row */
  }

  .reviews-center {
    display: none; /* 隐藏 reviews-center */
  }
}