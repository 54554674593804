.about-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    font-family: Poppins, sans-serif;
    max-width: 1000px; /* 页面最大宽度 */
    margin: 0px auto; /* 居中对齐 */
    margin-top: 50px;
  }
  
  /* 第一部分：图片左，文字右 */
  .section-one {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .section-one .image-container {
    flex: 4; /* 30% */
    margin-right: 40px;
  }
  
  .section-one .text-container {
    flex: 6; /* 70% */
  }
  
  .header-image {
    max-width: 100%;
    height: auto;
  }
  
  /* 第二部分：Icon 和 Chef 名字 */
  .section-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .chef-icon {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .chef-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  /* 第三部分：文字左，图片右 */
  .section-three {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .section-three .text-container {
    flex: 7; /* 70% */
    margin-right: 40px;
  }
  
  .section-three .image-container {
    flex: 3; /* 30% */
  }
  
  .chef-image {
    max-width: 100%;
    height: auto;
  }


  
  .section-four {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 四列 */
    width: 100%;
    gap: 20px;
  }
  
  .grid-image {
    width: 100%;
    height: auto;
    display: block;
    
  }
  
  /* ---- 手机端样式 ---- */
  @media (max-width: 768px) {
    .about-section {
      display: flex;
      gap: 0px;
      padding: 0px;
      margin: 0px 3vw; /* 居中对齐 */
      margin-top: 0px;
      
    }
    
    /* 整个页面布局：移除左右对齐，改为上下堆叠 */
    .section-one,
    .section-three {
      margin: 0px 0px;
      flex-direction: column; /* 改为上下布局 */
      align-items: center;
      text-align: center; /* 文字居中 */

    }
  
    .section-one .image-container,
    .section-three .image-container {
      margin: 0px 2vw;
      display: flex;
      justify-content: center;
      flex: none; /* 去除比例 */
      width: 100%; /* 图片宽度占满 */
      margin-bottom: 20px; /* 添加间距 */
      
    }
  
   .text-container
     {
      flex: none;
      text-align: left;
      margin: 0px 3vw;
    }
  
    /* 第二部分保持居中 */
    .section-two .chef-icon {
      width: 60px; /* 图标缩小 */
    }
  
    .section-two .chef-name {
      font-size: 16px; /* 字体略微缩小 */
      
    }
    .image-grid {
    grid-template-columns: 1fr; /* 一列 */
  }
  }
  